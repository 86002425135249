<template>
  <div id="table">
    <div class="select">
      <v-select
        :options="partyList"
        :reduce="(party) => party.code"
        label="name"
        v-model="defaultSelected"
        :value="party"
        dense
        solo
        :clearable="false"
        @input="changeParty"
      />
    </div>
    <DataTableGR24 v-if="renderSankey" :party="party" />
  </div>
</template>

<script>
import DataTableGR24 from "@/components/DataTableGR24.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "Table",
  components: {
    DataTableGR24,
    vSelect,
  },
  methods: {
    changeParty(party) {
      this.party = party;

      this.renderSankey = false;

      this.$nextTick(() => {
        this.renderSankey = true;
      });
    },
  },
  data() {
    return {
      party: "SP",
      renderSankey: true,
      defaultSelected: {
        name: "Sozialdemokratische Partei",
        code: "SP",
      },
      partyList: [
        {
          name: "Sozialdemokratische Partei",
          code: "SP",
        },
        {
          name: "Schweizerische Volkspartei",
          code: "SVP",
        },
        {
          name: "LDP Liberal-Demokratische",
          code: "LDP",
        },
        {
          name: "Grüne",
          code: "GRÜNE",
        },
        {
          name: "FDP.Die Liberalen",
          code: "FDP",
        },
        {
          name: "Grünliberale",
          code: "GLP",
        },
        {
          name: "Grünliberale",
          code: "GLP",
        },
        {
          name: "BastA!",
          code: "BastA",
        },
        {
          name: "Die Mitte",
          code: "Mitte",
        },
        {
          name: "Evangelische Volkspartei",
          code: "EVP",
        },
        {
          name: "Partei der Arbeit",
          code: "PdA",
        },
        {
          name: "Volks-Aktion",
          code: "VA",
        },
        {
          name: "Für Soziales und Sport",
          code: "FSSK",
        },
        {
          name: "Parteiloser Bürger",
          code: "PBkW",
        },
        {
          name: "Ein KUSS für Basel",
          code: "KUSS",
        },
      ],
    };
  },
};
</script>

<style scoped>
#table {
  margin-top: 43px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.select {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 15px;
  margin-left: 15%;
}
.v-select {
  background: white !important;
  width: 35%;
}
</style>
