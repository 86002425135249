<template>
  <div id="datatable">
    <div v-for="(dataSub, index) in data" :key="index" class="data-set">
      <div class="header">
        <h1>{{ dataSub.wahlkreis }}</h1>
        <input
          class="form-control"
          @keyup="onQuickFilterChange"
          type="text"
          :id="index"
          placeholder="Type text to filter..."
        />
      </div>
      <ag-grid-vue
        style="width: 100%; height: 350px;"
        class="datatable ag-theme-alpine"
        :rowData="dataSub.data"
        :columnDefs="columns"
        :modules="modules"
        @grid-ready="onReady"
        :defaultColDef="{
          sortable: true,
          resizable: true,
        }"
        :gridOptions="dataSub.gridOptions"
        :groupHeaders="true"
        :suppressRowClickSelection="true"
        rowSelection="multiple"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import Full2024 from "../data/2024_gr.json";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

export default {
  name: "DataTable",
  components: {
    AgGridVue,
  },
  props: ["party"],
  methods: {
    onReady(params) {
      //params.api.sizeColumnsToFit();
    },
    onQuickFilterChange(event) {
      console.log(event.target.value);
      console.log(this.data[event.target.id]);
      this.data[event.target.id].gridOptions.api.setQuickFilter(
        event.target.value
      );
    },
  },
  mounted() {
    this.columns = Object.freeze([
      {
        headerName: "Name",
        field: "ganzer_name",
      },
      {
        headerName: "Geschlecht",
        field: "geschlecht",
        width: "100",
      },
      {
        headerName: "Rangfolge",
        field: "rangfolge",
        width: "100",
        sort: "asc",
      },
      {
        headerName: "Total",
        field: "stimmen_total_aus_wahlzettel",
        width: "80",
      },
      {
        headerName: "Veränderte",
        field: "stimmen_veranderte_wahlzettel",
        width: "80",
      },

      /**
       *

        {
          name: "Volks-Aktion",
          code: "VA",
        },
        {
          name: "Für Soziales und Sport",
          code: "FSSK",
        },
        {
          name: "Parteiloser Bürger",
          code: "PBkW",
        },
        {
          name: "Ein KUSS für Basel",
          code: "KUSS",
        },
      ],
       */
      {
        headerName: "Ohne",
        field: "ohne",
        width: "80",
      },
      {
        headerName: "SP",
        field: "sp",
        width: "80",
      },
      {
        headerName: "SVP",
        field: "svp",
        width: "80",
      },
      {
        headerName: "LDP",
        field: "ldp",
        width: "80",
      },
      {
        headerName: "Grüne",
        field: "grüne",
        width: "80",
      },
      {
        headerName: "FDP",
        field: "fdp",
        width: "80",
      },
      {
        headerName: "GLP",
        field: "glp",
        width: "80",
      },
      {
        headerName: "BastA!",
        field: "basta",
        width: "80",
      },
      {
        headerName: "Mitte",
        field: "mitte",
        width: "80",
      },
      {
        headerName: "EVP",
        field: "evp",
        width: "80",
      },
      {
        headerName: "PdA",
        field: "pda",
        width: "80",
      },
      {
        headerName: "VA",
        field: "va",
        width: "80",
      },
      {
        headerName: "FSSK",
        field: "fssk",
        width: "80",
      },
      {
        headerName: "PBkW",
        field: "pbkw",
        width: "80",
      },
      {
        headerName: "KUSS",
        field: "kuss",
        width: "80",
      },
    ]);

    let spdata = this.rawData.filter((set) => {
      return set.parteikurzbezeichnung == this.party;
    });

    const rowStyleFunction = (params) => {
      console.log(params.data.gewahlt);
      if (params.data.gewahlt === "Gewählt") {
        return { background: "rgba(144, 238, 144, 0.2)" };
      }
      return null;
    };

    this.data.push({
      wahlkreis: "Kleinbasel",
      data: spdata.filter(
        (set) => set.wahlkreisbezeichnung == "Wahlkreis Kleinbasel"
      ),
      gridOptions: { getRowStyle: rowStyleFunction },
    });

    this.data.push({
      wahlkreis: "Grossbasel West",
      data: spdata.filter(
        (set) => set.wahlkreisbezeichnung == "Wahlkreis Grossbasel West"
      ),
      gridOptions: { getRowStyle: rowStyleFunction },
    });

    this.data.push({
      wahlkreis: "Grossbasel Ost",
      data: spdata.filter(
        (set) => set.wahlkreisbezeichnung == "Wahlkreis Grossbasel Ost"
      ),
      gridOptions: { getRowStyle: rowStyleFunction },
    });

    this.data.push({
      wahlkreis: "Riehen",
      data: spdata.filter(
        (set) => set.wahlkreisbezeichnung == "Wahlkreis Riehen"
      ),
      gridOptions: { getRowStyle: rowStyleFunction },
    });
  },
  data() {
    return {
      columns: [],
      modules: AllCommunityModules,
      rawData: Full2024,
      data: [],
    };
  },
};
</script>

<style>
#datatable {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 15px;
}

.data-set {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.datatable {
  width: 100%;
  height: 350px;
}

.ag-theme-alpine .ag-row,
.datatable {
  font-size: 12px;
}

.header {
  width: 100%;
  background-color: #00a18d;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  padding: 10px;
}

.form-control {
  width: 35% !important;
}
</style>
